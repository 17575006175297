<template>
  <el-main class="rh-medform">
    <form-parcourscliniqueedit
      :ismeddetail="true"
      :routetolist="pcRouteToList"
    />
  </el-main>
</template>
<script>
import FormParcourscliniqueedit from "@/components/rh/formulaires/FormParcourscliniqueedit";

export default {
  components: { "form-parcourscliniqueedit": FormParcourscliniqueedit },
  computed: {
    pcRouteToList() {
      return {
        name: "med_parcourscliniques",
        params: {
          idmed: this.$route.params.idmed,
        },
      };
    },
  },
};
</script>
